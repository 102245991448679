let shopHostname = window.location.hostname;
if (shopHostname.startsWith("client") || shopHostname.startsWith("localhost")) {
  shopHostname = "https://new.staging.carus-verlag.com";
} else {
  shopHostname = "https://www.carus-verlag.com";
}

export default {
  // product display information
  product: {
    name: "carus performance portal",
    version: "25.1.7.0",
    gitsha: "84f3e69bc71cad8a27058de273139b22b68da655",
    shopHostname,
  },

};
